import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import tw, { styled } from "twin.macro"
import { useForm } from "react-hook-form"
import HCaptcha from "@hcaptcha/react-hcaptcha"
import { Loading } from "../components/loading"
import Footer from "../components/Footer"
import LogRocket from "logrocket"
import DesignerForm from "../components/designerForm"
LogRocket.init("ziiqiw/studio-conscious")

const Item = styled.div`
  ${tw`grid lg:col-gap-16 lg:grid-cols-2 lg:mt-32`}
  iframe {
    ${tw`ml-auto mr-auto md:ml-0 block mt-5 mb-5`}
  }
  h1,
  h3,
  p {
    ${tw`p-5 lg:p-0`}
    line-height:2;
  }
  input,
  textarea {
    ${tw`mt-3 mb-3 p-2 w-10/12 block ml-auto mr-auto md:w-full`}
    border:none;
    border-bottom: 1px solid #000;
    &:focus {
      outline: none;
    }
  }
  textarea {
    resize: none;
    line-height: 22px;
    height: 100px;
  }
  input[type="submit"] {
    border: 1px solid #000;
    border-radius: 20px;
    background: #000;
    color: #fff;
    width: 300px;
    ${tw`ml-auto mr-auto md:ml-0 block mt-5 mb-5`}
    &.disabled {
      background: #78807a;
      color: #fff;
      &:hover {
        cursor: not-allowed;
      }
    }
  }
`

const ItemDesc = styled.div`
  ${tw`text-center`}
  background: ${({ background }) => background};
  margin-bottom:100px;
  @media (min-width: 600px) {
    padding:25px 0;
    margin-top: 100px;
  }
  color: #fff;
  span {
    display: block;
    @media (min-width: 1280px) {
      display: inline-block;
      padding: initial;
      width: 100%;
    }
    text-align: left;
    p.title {
      ${tw`text-lg font-bold`}
      margin-right:auto;
    }
  }
  p {
    line-height: 2;
    font-size:2.25rem;
    font-weight:700;
    font-family:"playfair Display";
    margin:0;
  }
  .border {
    text-align: center;
    width: 100%;
    height: 1px;
    border-left: none;
    border-top: 1px solid #fff;
    align-self: center;
    @media (min-width: 1280px) {
      border-left: 1px solid #fff;
      width: 1px;
      justify-self: center;
      height: 100%;
    }
  }
`

const ItemAsk = styled.div`
  @media (min-width: 600px) {
    margin-top: 100px;
  }

  p {
    line-height: 2;
  }
  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
    margin-bottom: 100px;
    @media (max-width: 1280px) {
      display: block;
    }
  }
  @media (max-width: 1280px) {
    display: block;
  }
  iframe {
    ${tw`ml-auto mr-auto md:ml-0 block mt-5 mb-5`}
  }
  h1,
  h3,
  p {
    ${tw`p-5 lg:p-0`}
  }
  h1 {
    display: inline-block;
    padding-right: 25px;
  }
  input,
  textarea {
    ${tw`mt-3 mb-3 p-2 w-10/12 block ml-auto mr-auto md:w-full`}
    border:none;
    border-bottom: 1px solid #000;
    &:focus {
      outline: none;
    }
  }
  textarea {
    resize: none;
    line-height: 22px;
    height: 100px;
  }
  input[type="submit"] {
    //border: 1px solid #000;
    border-radius: 20px;
    background: #565c4a;
    color: #fff;
    width: 300px;
    &:hover {
      cursor: pointer;
    }
    ${tw`ml-auto mr-auto md:ml-0 block mt-5 mb-5`}
    &.disabled {
      background: #78807a;
      color: #fff;
      &:hover {
        cursor: not-allowed;
      }
    }
  }
`

const Ask = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  margin: 0 auto;
  @media (min-width: 600px) {
    width: 50%;
  }
  justify-self: center;
  h1 {
    text-align: center;
    padding: 0;
    margin: 0 auto;
    display: block;
  }
`

const Full = styled.div`
  ${tw`w-3/4 mr-auto ml-auto`}
`

const ImgWrap = styled.div`
  width: 50%;
  margin: 0 auto;
`

const TextWrap = styled.div`
  width: 50%;
  margin: auto;
`

const LeftRight = styled.div`
  ${tw`w-3/4 mr-auto ml-auto grid lg:grid-cols-2`}
`
const RightLeft = styled.div`
  ${tw`w-3/4 mr-auto ml-auto grid lg:grid-cols-2`}
`

const DesignerWrap = styled.div`
  width: 75%;
  margin: 0 auto;
`

const ProcessTitle = styled.h2`
  text-align: center;
  font-size: 36px;
  font-family: "Playfair Display";
  margin-top: 150px;
`

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
}

const AskWrap = ({ location, data }) => {
  const { register, handleSubmit, watch, errors } = useForm()

  let fetchUrl
  console.log(location)
  if (location.origin === "http://gatsby.local:8000") {
    fetchUrl = `http://localhost:8888/.netlify/functions/product-question`
  } else if (location.origin === "http://dev.studioconscious.com") {
    fetchUrl = `https://studioconscious-dev.netlify.app/.netlify/functions/product-question`
  } else {
    fetchUrl = "/.netlify/functions/product-question"
  }

  const [captchaValue, setCaptchaValue] = useState(null)
  const [submitButton, setSubmitButton] = useState(true)
  const [captchaFailed, setCaptchaFailed] = useState(false)
  const [nextStage, setNextStage] = useState(false)
  const [failedEmail, setFailedEmail] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = async data => {
    setLoading(true)
    console.log(data)
    console.log(fetchUrl)
    const response = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({
        data,
        captcha: captchaValue,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data.captcha === false) {
          setCaptchaFailed(true)
        }
        if (data.email === true) {
          setNextStage(true)
        } else {
          setNextStage(true)
          setFailedEmail(true)
        }
      })
      .catch(error => {
        console.error("Error:", error)
        setNextStage(true)
        setFailedEmail(true)
      })
  }

  function retry() {
    setLoading(false)
  }

  const [close, setClose] = useState(false)

  function onVerifyCaptcha(token) {
    console.log(token)
    setCaptchaValue(token)
    setSubmitButton(false)
  }

  return (
    <DesignerWrap>
      <ProcessTitle>{data.sanityConceptsPage.packageFormTitle}</ProcessTitle>

      <DesignerForm location={location} />
    </DesignerWrap>
  )
}

const StoreDetails = ({ data, location }) => {
  return (
    <>
      {data.allSanityConcepts.edges.map(({ node: concept }, i) => {
        return (
          <React.Fragment key={i}>
            <Item>
              <Img
                fluid={concept.featureImage && concept.featureImage.asset.fluid}
              />
              <div>
                <h1>{concept.name}</h1>

                <BlockContent
                  blocks={concept._rawText}
                  serializers={serializers}
                />
              </div>
            </Item>
          </React.Fragment>
        )
      })}
    </>
  )
}

const WithLayout = Component => {
  return props => (
    <>
      <Layout location={props.location}>
        <Component {...props} />
      </Layout>
      {props.data.allSanityConcepts.edges.map(({ node: concept }, i) => {
        return (
          <React.Fragment key={i}>
            <ItemDesc background={concept.bannerBg && concept.bannerBg.hex}>
              <div>
                <p>{concept.bannerTitle}</p>
              </div>
            </ItemDesc>
            {concept.descBlock.map(block => {
              return (
                <>
                  <div>
                    {block._type === "full" && (
                      <Full>
                        <h1>{block.header}</h1>
                        <ImgWrap>
                          <Img fluid={block.image && block.image.asset.fluid} />
                        </ImgWrap>
                        <TextWrap>
                          <BlockContent
                            blocks={block._rawText}
                            serializers={serializers}
                          />
                        </TextWrap>
                      </Full>
                    )}
                    {block._type === "left-right" && (
                      <LeftRight>
                        <Img fluid={block.image && block.image.asset.fluid} />
                        <div>
                          <h1>{block.header}</h1>
                          <BlockContent
                            blocks={block._rawText}
                            serializers={serializers}
                          />
                        </div>
                      </LeftRight>
                    )}
                    {block._type === "right-left" && (
                      <RightLeft>
                        <div>
                          <h1>{block.header}</h1>
                          <BlockContent
                            blocks={block._rawText}
                            serializers={serializers}
                          />
                        </div>
                        <Img fluid={block.image && block.image.asset.fluid} />
                      </RightLeft>
                    )}
                  </div>
                </>
              )
            })}
          </React.Fragment>
        )
      })}
      <AskWrap data={props.data} location={props.location} />
      <Footer />
    </>
  )
}

export default WithLayout(StoreDetails)

export const query = graphql`
  query ConceptsDeatailsQuery($slug: String!) {
    sanityConceptsPage {
      packageFormTitle
    }
    allSanityConcepts(filter: { _id: { eq: $slug } }) {
      edges {
        node {
          name
          bannerTitle
          bannerBg {
            hex
          }
          _rawText(resolveReferences: { maxDepth: 10 })
          featureImage {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
          descBlock {
            ... on SanityFull {
              _key
              _type
              header
              _rawText
              image {
                asset {
                  fluid(maxWidth: 700) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            ... on SanityLeftRight {
              _key
              _type
              _rawText
              image {
                asset {
                  fluid(maxWidth: 700) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              header
            }
            ... on SanityRightLeft {
              _key
              _type
              _rawText
              image {
                asset {
                  fluid(maxWidth: 700) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              header
            }
            ... on SanityQuote {
              _key
              _type
              quote
            }
          }
        }
      }
    }
  }
`
